// header
.scoreheader {
  display: grid;
  grid-template-columns: 70px repeat(auto-fit, 200px);
  grid-template-rows: 1fr 1fr 1fr 50px;
  gap: 10px;
  // width: calc(8 * 100px + 7 * 10px); /* Set max width to fit 8 columns */
}

.headleaderboard {
  grid-row: 1/4;
  grid-column: 1/2;
  text-align: center;
  margin: auto;
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 5px 0;
}

.headtotal {
  grid-row: 4/5;
  grid-column: 1/2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
}

.headplayer {
  grid-row: 1/5;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.headotherplayer {
  grid-row: 1/5;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a6a6a6;
  border-radius: 10px;
}

// body
.scorebody {
  display: grid;
  gap: 0px 10px;
  grid-template-columns: 70px repeat(auto-fit, 200px);
  // width: calc(8 * 100px + 7 * 10px);
}
.scorehole {
  grid-column: 1/2;
}

.scorecard {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 20px 20px 20px 1fr;
  padding: 5px 10px;
  border-radius: 10px;

  &--eaglebg {
    background-color: #cdf2ff;
  }
  &--birdiebg {
    background-color: #dae3f3;
  }
  &--parbg {
    background-color: #caebc7;
  }
  &--bogeybg {
    background-color: #fff6dd;
  }
  &--doublebg {
    background-color: #feecde;
  }
  &--triplebg {
    background-color: #f3d4af;
  }
  &--nobg {
    background-color: #ffffff;
  }
  &--otherbg {
    background-color: #a6a6a6;
  }
}

.scorecardrow1 {
  grid-column: 1/-1;
  grid-row: 1/2;
}
.scorecardrow2 {
  display: flex;
  align-items: center;
  grid-column: 1/-1;
  grid-row: 2/4;
}
.scorecardrow3 {
  grid-column: 1/-1;
  grid-row: 4/5;
}
