.caddie-main-container {
  height: 215px;
  margin-bottom: 3px;
  border: 0;
  border-radius: 15px;
  /* background-color: transparent; */
  & .card-body {
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.list-container .slick-list {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
}
.caddie-card-container {
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  box-shadow: rgba(145, 145, 145, 0.116) 0px 1px 2px 0px,
    rgba(221, 221, 221, 0.089) 0px 1px 3px 1px;
}
.slick-arrow:before {
  color: rgba(0, 0, 0, 0.363);
}
.slick-arrow.slick-next {
  right: -20px;
}
.slick-arrow.slick-prev {
  left: -16px;
}
