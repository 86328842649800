#popover-notification {
  width: 500px;
}
.popover-container {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  outline: 1px solid rgba(255, 255, 255, 0.199);
}
.popover-body {
  max-height: 400px;
  overflow-y: auto;
  padding: 0;
}
.popover-head {
  /* margin-bottom: 0; */
}
.noti-list-container :hover {
  background-color: #ff5a992f;
}
.noti-item {
  position: relative;
  min-width: 250px;
  padding-top: 0;
}
.noti-item :hover {
  background-color: transparent;
}
.readcount-text {
  font-weight: 600;
  top: 0;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  background-color: var(--theme-color);
  color: white;
}
.red-dot {
  color: var(--theme-color);
  font-size: x-small;
}
.top-noti {
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
