.radio-container {
  position: relative;
  & .active {
    padding: 1.5rem;
    outline: 2px solid var(--theme-color) !important;
    border: none;
    background-color: #ffc0cb5f !important;
  }
  & .inactive {
    padding: 1.5rem;
    outline: 1px solid grey;
    border: none;
    background-color: white;
  }
  & .checked-icon {
    position: absolute;
    top: 0;
    right: 5px;
  }
}

.course-list-items:hover {
  background-color: rgba(204, 204, 204, 0.411);
}

.main-step-two-card {
  background-color: #f5f5f5;
  padding: 5px 10px;

  & .main-step-two-card-body {
    position: relative;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    & .caddie-container {
      margin-right: 15px;
    }
    & .caddie-container,
    .player-container {
      height: 80px;
    }

    & .caddie-container-none,
    .player-container-none {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f5f5f5;
    }

    & .step-two-card-more-dropdown {
      position: absolute;
      top: 0;
      right: -5px;
      & button {
        padding: 0;
        background-color: transparent;
        border: none;
      }
      & .step-two-card-more-btn {
        width: 30px;
        height: 30px;
      }
    }
  }
}
.user-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 5px;
}
/* menu btn */
.wizrd-more-btn svg svg #CreateRound g g g g path {
  fill: gray !important;
}

/* step three */
.step-three-radio-card {
  /* height: 64.5px; */
  /* padding: 1.5rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  outline: 1px solid grey;
  & .step-three-checked-icon,
  .step-one-checked-icon {
    display: none;
  }
}
.step-three-radio-card.active {
  position: relative;
  background-color: #ffc0cb5f;
  outline: 2px solid var(--theme-color);
  & .step-three-checked-icon,
  .step-one-checked-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 5px;
  }
}

.step-three-radio-card.active.disable {
  position: relative;
  background-color: #6b6b6b5f;
  outline: 2px solid var(--theme-color);
  & .step-three-checked-icon,
  .step-one-checked-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 5px;
  }
}
.add-round-cover-btn {
  padding: 10px;
  border: 2px dashed grey;
  border-radius: 0.25rem;
  cursor: pointer;
}
/* req preview */
.create-round-req-main {
  background-color: #f5f5f57c;
  /* padding: 15px; */
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  & .create-round-req-head {
    padding: 1rem 1rem 0.5rem;
    display: flex;
    justify-content: space-between;
    & .wizrd-more-btn {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
}
.create-round-req-main .create-round-req-body {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: end;
  justify-content: center;
}

.create-round-req-main .create-round-req-body .req-course-data {
  width: 100%;
  min-height: 80px;
  background-color: #f5f5f5c7;
  border-radius: 0.5rem;
  padding: 0 10px;
}
