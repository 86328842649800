.feather-x-circle::before {
  background-color: rgba(233, 245, 255, 0.5);
  border-radius: 50%;
}
.feather-x-circle {
  font-size: xx-large;
  color: rgb(109, 109, 109);
}
.post-text-input {
  width: 100%;
  height: inherit;
  max-height: 50vh;
  margin: 0.5rem 0;
  line-height: 1.5rem;
}
.create-post-add-photo-btn {
  width: 40px;
  cursor: pointer;
}
.modal-content {
  border-radius: 15px;
  padding: 0.5rem;
}

.post-modal-trigger {
  color: #888888;
  background-color: #f5f5f5;
  width: 100%;
  border-radius: 15px;
  cursor: text;
}

.each-image-container {
  position: relative;
  /* width: 150px;
  height: 150px; */
  padding: 0.4rem;
  box-sizing: border-box;
}

/* ios bug */
.selected-image-createpost div div div div {
  width: 150px;
  height: 150px;
}
.selected-image-editpost div div div div {
  width: 150px;
  height: 150px;
}
/* ios bug */

.each-image-container img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block; /* Helps with image rendering on iOS */
  box-sizing: border-box;
  border: 1px solid #00000041;
  padding: 5px;
  -webkit-border-radius: 15px; /* iOS-specific prefix */
  border-radius: 15px;
}

.each-image-container .more-btn {
  width: 100%;
  height: inherit; /* Or use a fixed height if needed */
  border: 1px solid #00000041;
  -webkit-border-radius: 15px; /* iOS-specific prefix */
  border-radius: 15px;
  box-sizing: border-box;
}

.each-image-container i {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px; /* Set specific width and height */
  height: 30px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  -webkit-border-radius: 50%; /* iOS-specific prefix */
  border-radius: 50%;
  cursor: pointer;
  color: #00000080;
}

.add-image-input-container {
  border-radius: 15px;
  width: 142px !important;
  height: 142px;
  border: 2px dashed #888888;
  cursor: pointer;
}
.post-warning-container i {
  font-size: 5rem;
  color: #e59e49;
}

.post-warning-confirm-btn {
  background-color: #ee3d7f !important;
}
.post-warning-cancel-btn {
  background-color: #f5f5f5 !important;
  color: black !important;
}

.post-warning-actions {
  justify-content: end !important;
  width: 90%;
}
.post-warning-text {
  margin-top: 1rem;
  & h2 {
    font-weight: 600;
  }
}
.priv-toggle-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border: none;
  width: 130px;
  & h4 {
    color: black;
  }
  &::after {
    display: none;
  }
}
.priv-dropdown-container > div {
  width: 220px;
  border-radius: 1rem;
  margin-top: 0.5rem;
  & a .priv-icon {
    width: 2rem;
  }
  & a div h4 {
    font-weight: 600;
  }
}
.priv-toggle-btn:hover {
  background-color: #f8f8f8;
}
.priv-toggle-btn:focus {
  background-color: #f8f8f8;
}
.priv-dropdown-container.show .priv-toggle-btn {
  background-color: #f8f8f8;
}
.priv-icon {
  width: 2rem;
}
