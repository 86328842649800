.square-card {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
}

.square-card .card-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0.5rem;
}

@media (min-width: 1200px) {
    .col-lg-2 {
        flex: 0 0 20%;
        max-width: 20%;
    }
}


.switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .switch-text {
    margin-right: 45px;
  }
  












  .ais-SearchBox {
    margin-bottom: 1em;
  }
  
  .ais-InstantSearch {
    max-width: 960px;
    width: 100%;
    display: block;
    overflow: hidden;
    margin: 0 auto
  }
  
  .ais-Hits-list .ais-Hits-item {
    margin-left: 0;
    width: 100%;
  }
  
  .ais-Hits-item img {
    margin-right: 1em
  }
  
  .ais-InstantSearch .ais-Hits-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    margin-left: 0;
    margin-top: 0;
  }

  .pageCaddie{
    padding-left: 0;
    padding-right: 0;
  }

  .bg-gradiant-bottom-2:after {
    content: "";
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 25%, rgba(0, 0, 0, 0.7) 100%);
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }