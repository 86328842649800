.preview-image-modal .modal-dialog .modal-content {
  pointer-events: none;
  background-color: transparent;
}
.preview-comment-image-modal {
  background-color: rgba(0, 0, 0, 0.3) !important;
  & .modal-dialog .modal-content {
    pointer-events: none;
    background: transparent;
    border: none;
  }
}
.preview-image-body,
.preview-comment-image-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
}

.image-preview,
.comment-image-preview {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  pointer-events: all;
}
.preview-comment-image-modal .modal-dialog {
  margin: 0;
  max-width: none;
}

.image-container {
  position: relative;
  width: 100%;
  padding-bottom: 75%; /* 4:3 aspect ratio */
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-image-container {
  height: 100%;
}

.each-post-image {
  width: 100%;
  padding-top: 100%;
  position: relative;
  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.post-image-container .post-image-slider .slick-dots {
  bottom: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  height: 20px;
  display: flex !important;
  justify-content: center;
  & li button::before {
    color: rgb(255, 255, 255);
  }
}
.privacy-icon {
  width: 16px;
}
.main-comment-body {
  height: calc(100vh - 240px);
  overflow-y: auto;
  padding: 5px;
  position: relative;

  & .comment-content-body {
    padding-top: 0;
  }
  & .comment-user-body {
    padding-bottom: 0;
  }
  & .comment-media-body {
    width: 100%;
    overflow: visible;
  }
  & .close-comment-btn {
    position: fixed;
    z-index: 1;
    top: 5px;
    right: 5px;
  }
}

.comment-footer {
  max-height: 184px;
}
.commentor-img img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.input-comment-container {
  max-height: 100px;
  overflow-y: auto;
}
.each-comment-image {
  height: 200px;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}
.comment-image-container {
  width: 100px;
  position: relative;
  & .comment-image {
    width: 100%;
    height: 100%;
  }
  & i {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    top: 0%;
    right: 0%;
    font-size: x-large;
    border-radius: 50%;
    cursor: pointer;
    color: #00000080;
  }
}

@media only screen and (max-width: 768px) {
  .main-comment-body > div {
    padding-left: 0;
    padding-right: 0;
  }
  .commentor-img {
    margin-right: 0.5rem !important;
    & img {
      width: 35px;
      height: 35px;
    }
  }
  .comment-modal .modal-dialog .modal-content {
    padding: 0;
  }
}

.delete_btn{
  color: red;
  font-weight: 600;
  font-size: medium;
}