@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);


.fredoka-font {font-family: 'Fredoka One', cursive;}
body{font-family: 'Montserrat', sans-serif; color: #111;font-size: 14px;font-weight: 400;}
.vh-100 {height: 100vh;}
.header-wrapper {position: absolute; top:0; width: 100%; padding:20px 0;  }
.p100 {padding: 100px 0;}
.pt100 {padding-top: 100px ;}
.pt50 {padding-top: 50px;}
.pb100 {padding-bottom: 100px; }
.pb50 {padding-bottom: 50px; }
.banner-content {padding: 0;position: absolute;top: 50%; width: 100%;transform: translateY(-50%);}
.bscover {background-size: cover; background-position: center bottom;}
.title-text {font-size: 62px; line-height: 1.23;color: #161537; }
.title-text span {color: #05f;}
.title-text2 {font-size: 50px; font-weight: 700; line-height: 1.2;color: #161537; }

.logo {text-align: left;display: flex;align-items: center; white-space: nowrap;line-height: 45px; }
.logo i {font-size: 35px; margin-right: 5px; color: #10d876 !important; }
.logo span {font-size: 30px; color: #05f;}
.logo:hover i,.logo:hover {outline: none; text-decoration: none; }

.btn {font-size: 11px; letter-spacing: 1px; font-weight: 600; }
.btn-lg {padding: 18px 55px; }
ul li a {font-weight: 600; font-size: 15px; }
p {line-height: 30px;  }
.icon-div {padding-left: 100px; position: relative; }
.mb80 {margin-bottom: 80px; }
.icon-div i {font-size: 40px; color: #132977; position: absolute; top:0px; left:20px;}
.icon-div h3 {font-size: 24px; margin-bottom: 15px;font-weight: 600;  }
.icon-div p {font-size: 13px; }
.demo-item {overflow: hidden;}
.demo-item .btn-wrap {top:-100px; transition: all 0.4s ease; width: 100%; left:0; position: absolute;  text-align: center; }
.demo-item .btn-wrap a {width: 150px; background-color: #222; color: #fff; font-size: 15px; font-weight: 600; line-height: 50px; height: 50px; display: inline-block; margin:4px; letter-spacing: 2px; border-radius: 5px; font-size: 13px; }
.demo-item .btn-wrap a:hover {text-decoration: none; }
.demo-item:hover .btn-wrap { top:33%; }
.demo-item span { padding: 30px 0 30px 0;text-align: center;display: block;text-decoration: none !important;font-weight: 600;color: #111;font-size: 16px;letter-spacing: 0.4px;text-decoration: none; } 
.demo-item a span { padding: 30px 0 30px 0;text-align: center;display: block;text-decoration: none !important;font-weight: 600;color: #111;font-size: 16px;letter-spacing: 0.4px;text-decoration: none; } 
.demo-item a:hover {text-decoration: none !important;}
.brand-wrapper {position: relative; top:-120px; }
.brand-wrapper img {margin-right: 20px;}
.brand-wrapper .col-sm-3 {display: flex;}
.brand-wrapper h4 {font-size: 16px; text-align: left; }
.brand-wrapper h4 span {font-size: 14px; font-weight: 400; margin-top: 5px; display: block;}
.bg-black{background-color: #111;}
.bg-primary {background-color: #132977;}
.banner-wrapper h4 {font-weight: 700; font-size: 50px; display: block; margin-bottom: 10px; margin-right: 40px; }
.banner-wrapper h4 span {display: block; font-size: 12px;font-weight: 600; line-height: 20px; }
.btn-primary {
	color: #fff;
	background-color: #132977;
	border-color: #132977;
}
.scroll-tiger {color: #132977;}
.rounded-3 {border-radius: 15px; }


.icon-scroll {position: relative;  left:15px;  }
.icon-scroll:before {position: absolute;right: -18px;}
.icon-scroll {width: 30px;height: 50px;margin-left: -20px;bottom: -100px;margin-top: -35px;box-shadow: inset 0 0 0 2px #007bff;border-radius: 25px;transition: right .5s;}
.icon-scroll:before {content: '';width: 6px;height: 6px;background: #007bff;margin-left: -4px;top: 8px;border-radius: 4px;animation-duration: 1.5s;animation-iteration-count: infinite;animation-name: scroll;left: 50%;}
.icon-scroll:after {content: "";position: absolute;z-index: -1; width: 58px;height: 80px;border-radius: 25px;transform: translate(-10px, -10px);transition: all .3s;}
.demo-nav.nav-tabs {}
.demo-nav.nav-tabs li {padding: 0 0px; }
.demo-nav.nav-tabs li a {font-size: 12px; font-weight: 700; color: #000; border:2px #000 solid; border-radius: 30px; padding: 15px 30px; }
.demo-nav.nav-tabs li a:hover,.nav-tabs li a:focus {text-decoration: none;}
.demo-nav.nav-tabs li a.active {background-color: #000; color: #fff;}
.btn-primary {
	color: #fff;
	background-color: #0069d9;
	border-color: #0062cc;
}
@keyframes scroll {0% {    opacity: 1;}100% {    opacity: 0;    transform: translateY(35px);}}

.theme-dark .demo-style.banner-wrapper h4,
.theme-dark .demo-style .title-text2,
.theme-dark .demo-style .title-text {color: #161537 !important;}
.theme-dark .demo-style.banner-wrapper {background-color: #fff !important;}
.theme-dark .demo-style {background-color: #fff !important;}
.theme-dark .demo-style.bg-black {background-color: #111 !important;}
.theme-dark .demo-style.bg-black .title-text2 {color: #fff !important; }


@media (min-width: 1800px) {
	.max-container {
		max-width: 1600px; 
	}
}
@media (max-width: 1500px) {
	.title-text {font-size: 48px; margin-top: 10% !important; margin-bottom: 40px !important;}
	.btn-lg {padding: 15px 40px;}
	.icon-scroll {bottom: -85px; }
	.brand-wrapper {top:-90px;}
	.banner-wrapper h4 {font-size: 38px; }
	.title-text2 {font-size: 45px; }
}
@media (max-width: 1300px) {
	.icon-scroll {display: none;}
	.bscover {background-repeat: no-repeat; background-position: center center !important;}
}
@media (max-width: 1024px) {
	.title-text { font-size: 32px; }
	.brand-wrapper img {max-width: 50px;height: -webkit-max-content;height: max-content;}
	.brand-wrapper h4 {font-size: 14px; }
	.banner-wrapper {    background-position: top left 50% !important;  }
}
@media (max-width: 768px) {
	.brand-wrapper {display: none;}
	/*.banner-wrapper {    background-position: top left 30% !important;  }*/
	.banner-wrapper h4 {font-size: 32px; margin-bottom: 0px; margin-right: 20px;  }
	.banner-wrapper h4 span {font-size: 11px; color: #333; line-height: 16px; }
	.col-xs-3 {width: 33.3%;}
	.col-xs-9 {width: 66.6%;}
	.title-text2 {font-size: 30px; }
	.demo-nav.nav-tabs li {height: 50px; }
	#mobile {display: none;}
}
@media (max-width: 480px) {
	.banner-wrapper { background-position: top left 23% !important;  }
	.list-inline li:last-child {margin-top: 10px; }
	.col-xs-6 {width: 50%;}
	.vh-100 {height: auto; padding-top: 100px; padding-bottom: 100px; }
	.banner-content {position: relative; top:0;transform: translateY(0%);}
}
#popover-notification {
  width: 500px;
}
.popover-container {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  outline: 1px solid rgba(255, 255, 255, 0.199);
}
.popover-body {
  max-height: 400px;
  overflow-y: auto;
  padding: 0;
}
.popover-head {
  /* margin-bottom: 0; */
}
.noti-list-container :hover {
  background-color: #ff5a992f;
}
.noti-item {
  position: relative;
  min-width: 250px;
  padding-top: 0;
}
.noti-item :hover {
  background-color: transparent;
}
.readcount-text {
  font-weight: 600;
  top: 0;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  background-color: var(--theme-color);
  color: white;
}
.red-dot {
  color: var(--theme-color);
  font-size: x-small;
}
.top-noti {
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.caddie-main-container {
  height: 215px;
  margin-bottom: 3px;
  border: 0;
  border-radius: 15px;
  /* background-color: transparent; */
  & .card-body {
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.list-container .slick-list {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
}
.caddie-card-container {
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  box-shadow: rgba(145, 145, 145, 0.116) 0px 1px 2px 0px,
    rgba(221, 221, 221, 0.089) 0px 1px 3px 1px;
}
.slick-arrow:before {
  color: rgba(0, 0, 0, 0.363);
}
.slick-arrow.slick-next {
  right: -20px;
}
.slick-arrow.slick-prev {
  left: -16px;
}

.feather-x-circle::before {
  background-color: rgba(233, 245, 255, 0.5);
  border-radius: 50%;
}
.feather-x-circle {
  font-size: xx-large;
  color: rgb(109, 109, 109);
}
.post-text-input {
  width: 100%;
  height: inherit;
  max-height: 50vh;
  margin: 0.5rem 0;
  line-height: 1.5rem;
}
.create-post-add-photo-btn {
  width: 40px;
  cursor: pointer;
}
.modal-content {
  border-radius: 15px;
  padding: 0.5rem;
}

.post-modal-trigger {
  color: #888888;
  background-color: #f5f5f5;
  width: 100%;
  border-radius: 15px;
  cursor: text;
}

.each-image-container {
  position: relative;
  /* width: 150px;
  height: 150px; */
  padding: 0.4rem;
  box-sizing: border-box;
}

/* ios bug */
.selected-image-createpost div div div div {
  width: 150px;
  height: 150px;
}
.selected-image-editpost div div div div {
  width: 150px;
  height: 150px;
}
/* ios bug */

.each-image-container img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block; /* Helps with image rendering on iOS */
  box-sizing: border-box;
  border: 1px solid #00000041;
  padding: 5px; /* iOS-specific prefix */
  border-radius: 15px;
}

.each-image-container .more-btn {
  width: 100%;
  height: inherit; /* Or use a fixed height if needed */
  border: 1px solid #00000041; /* iOS-specific prefix */
  border-radius: 15px;
  box-sizing: border-box;
}

.each-image-container i {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px; /* Set specific width and height */
  height: 30px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large; /* iOS-specific prefix */
  border-radius: 50%;
  cursor: pointer;
  color: #00000080;
}

.add-image-input-container {
  border-radius: 15px;
  width: 142px !important;
  height: 142px;
  border: 2px dashed #888888;
  cursor: pointer;
}
.post-warning-container i {
  font-size: 5rem;
  color: #e59e49;
}

.post-warning-confirm-btn {
  background-color: #ee3d7f !important;
}
.post-warning-cancel-btn {
  background-color: #f5f5f5 !important;
  color: black !important;
}

.post-warning-actions {
  justify-content: end !important;
  width: 90%;
}
.post-warning-text {
  margin-top: 1rem;
  & h2 {
    font-weight: 600;
  }
}
.priv-toggle-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border: none;
  width: 130px;
  & h4 {
    color: black;
  }
  &::after {
    display: none;
  }
}
.priv-dropdown-container > div {
  width: 220px;
  border-radius: 1rem;
  margin-top: 0.5rem;
  & a .priv-icon {
    width: 2rem;
  }
  & a div h4 {
    font-weight: 600;
  }
}
.priv-toggle-btn:hover {
  background-color: #f8f8f8;
}
.priv-toggle-btn:focus {
  background-color: #f8f8f8;
}
.priv-dropdown-container.show .priv-toggle-btn {
  background-color: #f8f8f8;
}
.priv-icon {
  width: 2rem;
}

.likebtnstyle_unlikebtn__1ag2Q div svg g g g circle{fill:#293145 !important;stroke:#212529;stroke-width:25px}

.preview-image-modal .modal-dialog .modal-content {
  pointer-events: none;
  background-color: transparent;
}
.preview-comment-image-modal {
  background-color: rgba(0, 0, 0, 0.3) !important;
  & .modal-dialog .modal-content {
    pointer-events: none;
    background: transparent;
    border: none;
  }
}
.preview-image-body,
.preview-comment-image-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
}

.image-preview,
.comment-image-preview {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  pointer-events: all;
}
.preview-comment-image-modal .modal-dialog {
  margin: 0;
  max-width: none;
}

.image-container {
  position: relative;
  width: 100%;
  padding-bottom: 75%; /* 4:3 aspect ratio */
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-image-container {
  height: 100%;
}

.each-post-image {
  width: 100%;
  padding-top: 100%;
  position: relative;
  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.post-image-container .post-image-slider .slick-dots {
  bottom: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  height: 20px;
  display: flex !important;
  justify-content: center;
  & li button::before {
    color: rgb(255, 255, 255);
  }
}
.privacy-icon {
  width: 16px;
}
.main-comment-body {
  height: calc(100vh - 240px);
  overflow-y: auto;
  padding: 5px;
  position: relative;

  & .comment-content-body {
    padding-top: 0;
  }
  & .comment-user-body {
    padding-bottom: 0;
  }
  & .comment-media-body {
    width: 100%;
    overflow: visible;
  }
  & .close-comment-btn {
    position: fixed;
    z-index: 1;
    top: 5px;
    right: 5px;
  }
}

.comment-footer {
  max-height: 184px;
}
.commentor-img img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.input-comment-container {
  max-height: 100px;
  overflow-y: auto;
}
.each-comment-image {
  height: 200px;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}
.comment-image-container {
  width: 100px;
  position: relative;
  & .comment-image {
    width: 100%;
    height: 100%;
  }
  & i {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    top: 0%;
    right: 0%;
    font-size: x-large;
    border-radius: 50%;
    cursor: pointer;
    color: #00000080;
  }
}

@media only screen and (max-width: 768px) {
  .main-comment-body > div {
    padding-left: 0;
    padding-right: 0;
  }
  .commentor-img {
    margin-right: 0.5rem !important;
    & img {
      width: 35px;
      height: 35px;
    }
  }
  .comment-modal .modal-dialog .modal-content {
    padding: 0;
  }
}

.delete_btn{
  color: red;
  font-weight: 600;
  font-size: medium;
}
.swal2-loader {
    border-color: #ee3d7f !important; /* เปลี่ยนสีของเส้น */
    border-top-color: transparent !important; /* สีของด้านบน */
  }
.about-div {
  border-radius: 10px;
  padding: 1rem;
  max-height: 150px;
  min-height: 70px;
  overflow: auto;
}
.create-round-card .card-body .card {
  box-shadow: none !important;
}

.profile-cover-card {
  position: relative;
  & .profile-edit-image-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 42px;
    cursor: pointer;
  }
}
.profile-image-card {
  & .profile-edit-image-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 42px;
    cursor: pointer;
  }
}

.main-pic,
.second-pic,
.third-pic {
  position: relative;
}

.select-pic-icon,
.delete-pic-icon {
  position: absolute;
  bottom: 12px;
  right: 28px;
  opacity: 0.8;
  cursor: pointer;
  box-shadow: 0 0 3px 1px #00000080;
}
.delete-pic-icon {
  font-size: 1.3rem;
  background-color: #f5f5f5;
  border-radius: 100%;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-pic-icon {
  width: 44px;
  border-radius: 100%;
  background-color: #f5f5f5;
}
.infinite-scroll-component {
  overflow: unset !important;
}

.scorestyle_scoreheader__Mn6p1{display:grid;grid-template-columns:70px repeat(auto-fit, 200px);grid-template-rows:1fr 1fr 1fr 50px;grid-gap:10px;gap:10px}.scorestyle_headleaderboard__1IT57{grid-row:1/4;grid-column:1/2;text-align:center;margin:auto;border-radius:10px;background-color:#f5f5f5;padding:5px 0}.scorestyle_headtotal__29JCE{grid-row:4/5;grid-column:1/2;display:flex;justify-content:center;align-items:center;flex-direction:column;border-radius:10px;background-color:#f5f5f5;width:100%;height:100%}.scorestyle_headplayer__Qhye3{grid-row:1/5;display:flex;justify-content:center;align-items:center;background-color:#f5f5f5;border-radius:10px}.scorestyle_headotherplayer__3J-XB{grid-row:1/5;display:flex;justify-content:center;align-items:center;background-color:#a6a6a6;border-radius:10px}.scorestyle_scorebody__foz7X{display:grid;grid-gap:0px 10px;gap:0px 10px;grid-template-columns:70px repeat(auto-fit, 200px)}.scorestyle_scorehole__2McT1{grid-column:1/2}.scorestyle_scorecard__28jS_{display:grid;grid-template-columns:repeat(5, 1fr);grid-template-rows:20px 20px 20px 1fr;padding:5px 10px;border-radius:10px}.scorestyle_scorecard--eaglebg__4PCz9{background-color:#cdf2ff}.scorestyle_scorecard--birdiebg__1b2s9{background-color:#dae3f3}.scorestyle_scorecard--parbg__31SRj{background-color:#caebc7}.scorestyle_scorecard--bogeybg__3j87n{background-color:#fff6dd}.scorestyle_scorecard--doublebg__3-B6W{background-color:#feecde}.scorestyle_scorecard--triplebg__3DhEv{background-color:#f3d4af}.scorestyle_scorecard--nobg__obvlM{background-color:#ffffff}.scorestyle_scorecard--otherbg__35Zl9{background-color:#a6a6a6}.scorestyle_scorecardrow1__165R3{grid-column:1/-1;grid-row:1/2}.scorestyle_scorecardrow2__1IHbH{display:flex;align-items:center;grid-column:1/-1;grid-row:2/4}.scorestyle_scorecardrow3__3MSk1{grid-column:1/-1;grid-row:4/5}

.radio-container {
  position: relative;
  & .active {
    padding: 1.5rem;
    outline: 2px solid var(--theme-color) !important;
    border: none;
    background-color: #ffc0cb5f !important;
  }
  & .inactive {
    padding: 1.5rem;
    outline: 1px solid grey;
    border: none;
    background-color: white;
  }
  & .checked-icon {
    position: absolute;
    top: 0;
    right: 5px;
  }
}

.course-list-items:hover {
  background-color: rgba(204, 204, 204, 0.411);
}

.main-step-two-card {
  background-color: #f5f5f5;
  padding: 5px 10px;

  & .main-step-two-card-body {
    position: relative;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    & .caddie-container {
      margin-right: 15px;
    }
    & .caddie-container,
    .player-container {
      height: 80px;
    }

    & .caddie-container-none,
    .player-container-none {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f5f5f5;
    }

    & .step-two-card-more-dropdown {
      position: absolute;
      top: 0;
      right: -5px;
      & button {
        padding: 0;
        background-color: transparent;
        border: none;
      }
      & .step-two-card-more-btn {
        width: 30px;
        height: 30px;
      }
    }
  }
}
.user-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 5px;
}
/* menu btn */
.wizrd-more-btn svg svg #CreateRound g g g g path {
  fill: gray !important;
}

/* step three */
.step-three-radio-card {
  /* height: 64.5px; */
  /* padding: 1.5rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  outline: 1px solid grey;
  & .step-three-checked-icon,
  .step-one-checked-icon {
    display: none;
  }
}
.step-three-radio-card.active {
  position: relative;
  background-color: #ffc0cb5f;
  outline: 2px solid var(--theme-color);
  & .step-three-checked-icon,
  .step-one-checked-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 5px;
  }
}

.step-three-radio-card.active.disable {
  position: relative;
  background-color: #6b6b6b5f;
  outline: 2px solid var(--theme-color);
  & .step-three-checked-icon,
  .step-one-checked-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 5px;
  }
}
.add-round-cover-btn {
  padding: 10px;
  border: 2px dashed grey;
  border-radius: 0.25rem;
  cursor: pointer;
}
/* req preview */
.create-round-req-main {
  background-color: #f5f5f57c;
  /* padding: 15px; */
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  & .create-round-req-head {
    padding: 1rem 1rem 0.5rem;
    display: flex;
    justify-content: space-between;
    & .wizrd-more-btn {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
}
.create-round-req-main .create-round-req-body {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: end;
  justify-content: center;
}

.create-round-req-main .create-round-req-body .req-course-data {
  width: 100%;
  min-height: 80px;
  background-color: #f5f5f5c7;
  border-radius: 0.5rem;
  padding: 0 10px;
}

.square-card {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
}

.square-card .card-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0.5rem;
}

@media (min-width: 1200px) {
    .col-lg-2 {
        flex: 0 0 20%;
        max-width: 20%;
    }
}


.switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .switch-text {
    margin-right: 45px;
  }
  







  .carousel-indicators {
    margin-bottom: 0rem;
  }




  .ais-SearchBox {
    margin-bottom: 1em;
  }
  
  .ais-InstantSearch {
    max-width: 960px;
    width: 100%;
    display: block;
    overflow: hidden;
    margin: 0 auto
  }
  
  .ais-Hits-list .ais-Hits-item {
    margin-left: 0;
    width: 100%;
  }
  
  .ais-Hits-item img {
    margin-right: 1em
  }
  
  .ais-InstantSearch .ais-Hits-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    margin-left: 0;
    margin-top: 0;
  }

  .pageCaddie{
    padding-left: 0;
    padding-right: 0;
  }

  .bg-gradiant-bottom-2:after {
    content: "";
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 25%, rgba(0, 0, 0, 0.7) 100%);
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
/* player */
.main-player-stat {
  & section div strong {
    font-size: 32px;
    color: white;
  }
  & section div span {
    font-size: 14px;
    font-weight: 700;
  }
}

/* caddie */
.main-caddie-stat {
  & section div strong {
    font-size: 32px;
    color: white;
  }
  & section div span {
    font-size: 14px;
    font-weight: 700;
  }
}

/* clubs */
.add-club-type,
.add-club-num,
.add-club-loft,
.add-club-dist {
  background-color: #f5f5f5;
  text-align: center;
  padding: 3px 0;
  outline: 1px solid #B0B5BD;
}

.add-club-container {
  padding: 0px 15px;
}
.add-club-main-body {
  height: 310px;
}

.square-card {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
}

.square-card .card-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0.5rem;
}

@media (min-width: 1200px) {
    .col-lg-2 {
        flex: 0 0 20%;
        max-width: 20%;
    }
}


.switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .switch-text {
    margin-right: 45px;
  }
  












  .ais-SearchBox {
    margin-bottom: 1em;
  }
  
  .ais-InstantSearch {
    max-width: 960px;
    width: 100%;
    display: block;
    overflow: hidden;
    margin: 0 auto
  }
  
  .ais-Hits-list .ais-Hits-item {
    margin-left: 0;
    width: 100%;
  }
  
  .ais-Hits-item img {
    margin-right: 1em
  }
  
  .ais-InstantSearch .ais-Hits-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    margin-left: 0;
    margin-top: 0;
  }

  .pageCaddie{
    padding-left: 0;
    padding-right: 0;
  }

  .bg-gradiant-bottom-2:after {
    content: "";
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 25%, rgba(0, 0, 0, 0.7) 100%);
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
.square-card {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
}

.square-card .card-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0.5rem;
}

@media (min-width: 1200px) {
  .col-lg-2 {
      flex: 0 0 20%;
      max-width: 20%;
  }
}


.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switch-text {
  margin-right: 45px;
}













.ais-SearchBox {
  margin-bottom: 1em;
}

.ais-InstantSearch {
  max-width: 960px;
  width: 100%;
  display: block;
  overflow: hidden;
  margin: 0 auto
}

.ais-Hits-list .ais-Hits-item {
  margin-left: 0;
  width: 100%;
}

.ais-Hits-item img {
  margin-right: 1em
}

.ais-InstantSearch .ais-Hits-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  margin-left: 0;
  margin-top: 0;
}

.pageMyFavorite{
  padding-left: 0;
  padding-right: 0;
}

.bg-gradiant-bottom-2:after {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 25%, rgba(0, 0, 0, 0.7) 100%);
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
