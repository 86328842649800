.about-div {
  border-radius: 10px;
  padding: 1rem;
  max-height: 150px;
  min-height: 70px;
  overflow: auto;
}
.create-round-card .card-body .card {
  box-shadow: none !important;
}

.profile-cover-card {
  position: relative;
  & .profile-edit-image-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 42px;
    cursor: pointer;
  }
}
.profile-image-card {
  & .profile-edit-image-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 42px;
    cursor: pointer;
  }
}

.main-pic,
.second-pic,
.third-pic {
  position: relative;
}

.select-pic-icon,
.delete-pic-icon {
  position: absolute;
  bottom: 12px;
  right: 28px;
  opacity: 0.8;
  cursor: pointer;
  box-shadow: 0 0 3px 1px #00000080;
}
.delete-pic-icon {
  font-size: 1.3rem;
  background-color: #f5f5f5;
  border-radius: 100%;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-pic-icon {
  width: 44px;
  border-radius: 100%;
  background-color: #f5f5f5;
}
.infinite-scroll-component {
  overflow: unset !important;
}
